import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';

const LimbusDatabaseCombatPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/limbus-company/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Combat mechanics</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/limbuscompany/categories/category_combat.jpg"
            alt="Combat mechanics"
          />
        </div>
        <div className="page-details">
          <h1>Combat mechanics</h1>
          <h2>A guide that explains the combat in Limbus Company.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Skills" />
        <p>
          In Limbus Company, each Sinner and each of their Identities will have
          access to skills during the combat. Each of the skills can deal one of
          the three damage types:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/combat/image3.png"
          alt="Combat Guide"
        />
        <ul>
          <li>Slash</li>
          <li>Pierce</li>
          <li>Blunt</li>
        </ul>
        <p>
          Allies and enemies will have different resistances or weaknesses to
          those damage types, so it is important to pick appropriate skills.
        </p>
        <p>
          On top of those three damage types, each skill will be associated with
          one of the seven “Sin Affinities”, those being, which are also a
          subject to resistances and weaknesses:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/combat/image5.png"
          alt="Combat Guide"
        />
        <ul>
          <li>Wrath</li>
          <li>Lust</li>
          <li>Sloth</li>
          <li>Gluttony</li>
          <li>Gloom</li>
          <li>Pride</li>
          <li>Envy</li>
        </ul>
        <StaticImage
          src="../../../images/limbuscompany/combat/image4.png"
          alt="Combat Guide"
        />
        <p>
          While not affecting damage by themselves, chaining two + skills
          associated with the same sin, will cause them to resonate, increasing
          skill potency. Those further in the chain will benefit with higher
          power increase.{' '}
        </p>
        <StaticImage
          src="../../../images/limbuscompany/combat/image6.png"
          alt="Combat Guide"
        />
        <p>
          Additionally, if you can chain 3 or more Sins in succession, Absolute
          Resonance will activate, boosting skills power even further based on
          their number.
        </p>
        <StaticImage
          src="../../../images/limbuscompany/combat/image7.png"
          alt="Combat Guide"
        />
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Resistances and Weaknesses" />
        <p>
          Resistances to slash, pierce and blunt damage, are divided into five
          tiers:
        </p>
        <ul>
          <li>Fatal - 2x damage received</li>
          <li>Weak - 1,5x damage received</li>
          <li>Normal - 1x damage received</li>
          <li>Endure - 0,5x damage received</li>
          <li>Ineffective - 0,25x damage received</li>
        </ul>
        <p>
          Those numbers assume we will follow Library of Ruina numbers for
          those. Additionally, Sin affinity also changes how much damage will be
          received or dealt. Let's look at the base Identity Heatcliff below:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/combat/image2.png"
          alt="Combat Guide"
        />
        <p>
          Heatcliff will receive two times more damage from Lust and Gluttony
          affiliated skills, take 0,75 damage from Envy skill and normal damage
          from rest Sins.
        </p>
        <SectionHeader title="Coins" />
        <p>
          In Limbus Company, outcome is determined by flipping coins. Each skill
          has at least one coin. Skills have fixed base power while coins add or
          remove power from the skill.
        </p>
        <StaticImage
          src="../../../images/limbuscompany/combat/image9.png"
          alt="Combat Guide"
        />
        <p>
          Coin bonus applies when the coin lands on the Heads. As already
          mentioned, coins can add or remove power from the skill. In the case
          of skills with multiple coins, the result of one coin affects the next
          coin result too.
        </p>
        <p>
          Skills and coins will have different unique effects, based on whether
          the coin flips on heads or tails. Head will not always be the desired
          outcome. While this system at first looks to have the outcome be
          always a 50/50 result, Sanity system adjusts it. When Sanity is
          positive , odds of rolling “good” outcomes are increased and when
          Sanity is negative, “bad” outcomes will happen more often.
        </p>
        <SectionHeader title="Clashes" />
        <p>
          When both sides attack each other with a skill, Clash occurs. Clash is
          a comparison of two skills’ powers. It is possible to cancel an attack
          with a Clash.
        </p>
        <p>
          In Combat, allies and enemies will always attack the closest target
          (usually the slowest), however, each ally and enemy have their speed
          rolled at the start of each turn, it is possible to intercept attacks
          if one's speed is higher than the opposing side’s.
        </p>
        <StaticImage
          src="../../../images/limbuscompany/combat/image8.png"
          alt="Combat Guide"
        />
        <p>
          When fighting Abnormalities, the clash will occur when skill is set
          directly against the Abnormality.{' '}
        </p>
        <StaticImage
          src="../../../images/limbuscompany/combat/image1.png"
          alt="Combat Guide"
        />
        <p>
          With it, both non-abnormality and abnormality battles have different
          dynamics set to them.
        </p>
        <p>
          Clash outcome is the outcome of the final number on the skill,
          factoring in all the bonuses. Winner of the Clash is the one with
          higher power.
        </p>
        <p>
          During Clash, both sides Skill power and one coin will clash at the
          same time. If one side loses a clash, they lose the clashing coin and
          move to the next coin, unless there is no more. In that case, Clash
          ends and the winning side performs a direct attack.{' '}
        </p>
        <p>
          Depending on the skill and amount of remaining coins, skill can hit
          the enemy multiple times.
        </p>
        <SectionHeader title="Sanity" />
        <p>
          Sanity was mentioned already when talking about coins and their odds.
        </p>
        <p>
          Sanity, also known as SP, will greatly affect the flow of combat.
          Except for Abnormalities, each character has Sanity. Each battle
          Sanity starts at 0 and can go up to +45 or go down to -45. However, in
          Story and Mirror dungeons Sanity level will carry over towards the
          next fight.
        </p>
        <p>
          Sanity increases when a clash is won or an enemy dies. Sanity
          decreases when clash is lost, ally character dies or E.G.O is used.
          Death of an ally or enemy impacts the whole team’s Sanity.
        </p>
        <ul>
          <li>
            Positive level of Sanity (between +1 and 45) will cause coins to
            yield favourable results.
          </li>
          <li>
            Negative level of Sanity (between -1 and -45) will cause enemies to
            panic and Sinners to get out of our control temporarily.
          </li>
        </ul>
        <SectionHeader title="E.G.O" />
        <p>
          E.G.O or Extermination of Geometrical Organ are a special type of
          skill that requires a specific number of Sins used beforehand and
          Sanity. For example, using a skill with Greed affinity, will give a
          player 1 affinity resource.
        </p>
        <p>
          Each E.G.O has their own set of resistances that apply to the Sinner
          after using an E.G.O skill. However, not all resistances are changed.
          Only stronger resistances from the E.G.O are applied so there is no
          worry of becoming more fragile.
        </p>
        <p>
          While using E.G.O drains Sinner's sanity, it is possible to use it
          even if Sinner is at the very bottom. Each time E.G.O is used while
          Sinner is under 0 Sanity threshold, there is a chance to use a
          Corroded, uncontrollable version of the E.G.O attack that may target
          allies. When E.G.O is used while at -45 Sanity, Sinner goes out of
          control into E.G.O corrosion state, all their attacks become E.G.O
          attacks and they attack everybody, foe and ally alike.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default LimbusDatabaseCombatPage;

export const Head: React.FC = () => (
  <Seo
    title="Combat mechanics | Limbus Company | Prydwen Institute"
    description="A guide that explains the combat in Limbus Company."
  />
);
